import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Grid, Card, CardMedia, CardContent, Typography, CircularProgress, Pagination, Box, Container } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import { formatDate, removeHtmlTags, truncateText } from "../../helper/utils"
import { Helmet } from "react-helmet";

const SinglePage = () => {
  const { category } = useParams();
  const [posts, setPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(16);
  const [totalPages, setTotalPages] = useState(1);  // Track total pages

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        setIsLoading(true);
        const result = await axios.get(`https://echtzeitnachrichten.de/admin/api/category/news/${category}`);
        const sortedData = result.data.news.sort((a, b) => new Date(b.date) - new Date(a.date));
        setPosts(sortedData || []);
        setTotalPages(Math.ceil(sortedData.length / postsPerPage));  // Set total pages
        setIsLoading(false);
      } catch (error) {
        setError('Failed to load posts');
        setIsLoading(false);
      }
    };
    fetchPosts();
  }, [category]);

  const handleChangePage = (event, value) => setCurrentPage(value);

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);

  if (isLoading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </div>
    );
  }

  if (error) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Typography variant="h6" color="error">{error}</Typography>
      </div>
    );
  }

  return (
    <>
    <Helmet>
        <title>{category}</title>
      </Helmet>
    <Box sx={{ padding: '40px 0px', fontFamily: 'Sen, sans-serif', borderTop: '0.5px solid gray' }}>
      <Container sx={{ width: '90%', mx: 'auto' }}>
        <Typography
          variant="h4"
          sx={{ marginBottom: '20px', fontSize: '24px' }}
        >
          {category.charAt(0).toUpperCase() + category.slice(1)}-Nachrichten
        </Typography>

        {/* Grid layout for displaying posts */}
        <Grid container spacing={4} sx={{ padding: '40px 0px' }}>
          {currentPosts.map((post) => (
            <Grid item xs={12} sm={6} md={3} key={post._id}>
              <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%', boxShadow: 'none', borderRadius: '10px', transition: 'transform 0.3s, box-shadow 0.3s', "&:hover": { transform: 'translateY(-5px)', boxShadow: '0 8px 20px rgba(0, 0, 0, 0.2)' } }}>
                <Link to={`/${post.slug}`} style={{ textDecoration: 'none' }}>
                  <CardMedia
                    component="img"
                    image={post.image || '/default-image.jpg'}
                    alt={post.title}
                    sx={{ height: '200px', objectFit: 'cover', borderRadius: '10px' }}
                  />
                </Link>
                <CardContent sx={{ flexGrow: 1 }}>
                  <Link to={`/${post.slug}`} style={{ textDecoration: 'none' }}>
                    <Typography variant="caption" sx={{ color: 'gray', textTransform: 'uppercase', fontSize: '12px' }}>
                      {post.category}
                    </Typography>
                  </Link>

                  <Typography variant="body2" color="textSecondary" sx={{ fontSize: '12px', marginTop: '4px', textDecoration: 'none' }}>
                    <Link to={`/schriftsteller/${post.writerName}`} style={{ textDecoration: 'none' }}>
                      Von {post.writerName}
                    </Link> • {formatDate(post.date)}
                  </Typography>
                  <Link to={`/${post.slug}`} style={{ textDecoration: 'none' }}>
                    <Typography variant="h6" component="p" sx={{ fontWeight: 'bold', fontSize: '16px', color: 'black' }}>
                      {truncateText(removeHtmlTags(post?.title), "title")}
                    </Typography>
                  </Link>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>

        {/* Pagination Controls */}
        <Box sx={{ mt: 4, mb: 4, display: 'flex', justifyContent: 'center' }}>
          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={handleChangePage}
            color="primary"
          />
        </Box>
      </Container>
    </Box>
    </>
  );
};

export default SinglePage;
