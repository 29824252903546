import React, { useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import axios from 'axios';
import {
  Container, Box, Typography, Card, CardContent, CardMedia, Avatar, Grid, CircularProgress, Pagination,
} from '@mui/material';
import { removeHtmlTags } from '../../helper/utils';
import { Helmet } from 'react-helmet';

const SearchResults = () => {
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 18; // Number of posts per page
  const location = useLocation();
  const query = new URLSearchParams(location.search).get('query') || ''; // Get query from URL

  useEffect(() => {
    const fetchResults = async () => {
      if (!query) return;

      setLoading(true);
      try {
        const response = await axios.get('https://echtzeitnachrichten.de/admin/api/search/news', { params: { value: query } });
        if (response.data && response.data.news) {
          setResults(response.data.news || []);
        } else {
          setResults([]);
        }
        setError(null);
      } catch (error) {
        setError('Failed to fetch search results.');
      } finally {
        setLoading(false);
      }
    };

    fetchResults();
  }, [query]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const truncateDescription = (description) => {
    return description.length > 250 ? description.slice(0, 250) + '...' : description;
  };

  const limitTitleToSixWords = (title) => {
    const words = title.split(' ');
    return words.length > 6 ? words.slice(0, 6).join(' ') + '...' : title;
  };

  const formatDate = (date) => {
    const formattedDate = new Date(date);
    return !isNaN(formattedDate.getTime())
      ? formattedDate.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })
      : 'Invalid Date';
  };

  if (loading) {
    return <CircularProgress sx={{ display: 'block', margin: 'auto' }} />;
  }

  if (error) {
    return <Typography variant="h6" color="error" align="center">{error}</Typography>;
  }

  // Calculate pagination
  const totalPages = Math.ceil(results.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const paginatedResults = results.slice(startIndex, startIndex + itemsPerPage);

  return (
    <>
    <Helmet><title>Suchergebnisse für "{query}"</title></Helmet>
    <Container sx={{ py: 4, pb: "70px" }}>
      <Box sx={{ textAlign: 'center', mb: 4 }}>
        <Typography variant="h4" color="primary" gutterBottom>
        Suchergebnisse für <span style={{ color: '#f50057' }}>"{query}"</span>
        </Typography>
      </Box>
      {results.length === 0 ? (
        <Typography variant="h6" color="textSecondary" align="center">
          Keine Ergebnisse gefunden
        </Typography>
      ) : (
        <>
          <Grid container spacing={4}>
            {paginatedResults.map((item) => (
              <Grid item xs={12} sm={6} md={4} key={item.id}>
                <Link to={`/${item.slug}`} style={{ textDecoration: 'none' }}>
                  <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                    <CardMedia
                      component="img"
                      alt={item.title}
                      image={item.image || 'default-image.jpg'}
                      sx={{ height: 200, objectFit: 'cover' }}
                    />

                    <CardContent sx={{ flexGrow: 1 }}>
                    <Link to={`/${item.slug}`} style={{ textDecoration: 'none' }}>
                  <Typography
                    variant="caption"
                    sx={{
                      color: "gray",
                      textTransform: "uppercase",
                      fontSize: "12px",
                    }}
                  >
                    {item.category}

                  </Typography>
                </Link>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  sx={{ fontSize: "12px", marginTop: "4px", textDecoration: "none" }}
                >
                  <Link to={`/schriftsteller/${item.writerName}`} style={{ textDecoration: 'none' }}>
                  Von {item.writerName}
                  </Link>  • {formatDate(item.date)}
                </Typography>
                      <Typography variant="h6" color="textPrimary" gutterBottom>
                        {limitTitleToSixWords(item.title)}
                      </Typography>
                      <Typography variant="body2" color="textSecondary" paragraph>
                        {removeHtmlTags(truncateDescription(item.description))}
                      </Typography>
                    </CardContent>
                  </Card>
                </Link>
              </Grid>
            ))}
          </Grid>
          <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center' }}>
            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={handlePageChange}
              color="primary"
            />
          </Box>
        </>
      )}
    </Container>
    </>
  );
};

export default SearchResults;
