import React, { useEffect, useState } from "react";
import { Box, Grid, Typography, Card, CardMedia, CardContent, Container } from "@mui/material";
import axios from "axios";
import { removeHtmlTags, formatDate, truncateText } from "../../helper/utils";
import { Link } from "react-router-dom";
const fetchNationNews = async () => {
  try {
    const response = await axios.get("https://echtzeitnachrichten.de/admin/api/category/news/Unterhaltung");
    return response.data.news;
  } catch (error) {
    console.error("Error fetching nation news:", error);
    return [];
  }
};

const NationNews = () => {
  const [news, setNews] = useState([]);

  useEffect(() => {
    const getNews = async () => {
      const fetchedNews = await fetchNationNews();
      setNews(fetchedNews.slice(0, 6)); // Limit the news to 6 posts
    };
    getNews();
  }, []);

  return (
    <Box sx={{ padding: "16px 0", backgroundColor: "#ffffff" }}>
      <Container>
        {/* Section Title */}
        <Typography
          variant="h5"
          component="h2"

          sx={{
            padding: "0px 16px",
            fontWeight: "bold",
            mb: 2,
            fontSize: "22px",
            margin: "30px 0px",
            position: "relative",
            paddingLeft: "16px",
            "&::before": {
              content: '""',
              position: "absolute",
              left: 0,
              top: "50%",
              transform: "translateY(-50%)",
              width: "4px",
              height: "100%",
              backgroundColor: "red", // Changed to red
              borderRadius: "3px",
            },
            color: "red", // Changed text color to red
          }}
        >
          Unterhaltung
        </Typography>


        <Grid container spacing={3}>
          {news.map((item, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card
                sx={{
                  boxShadow: 2,
                  borderRadius: "8px",
                  transition: "transform 0.3s ease",
                  "&:hover": { transform: "scale(1.03)" },
                }}
              >
                <Link to={`/${item.slug}`} style={{ textDecoration: "none" }}>
                  <CardMedia
                    component="img"
                    image={item?.image || "https://via.placeholder.com/300"}
                    alt={removeHtmlTags(item?.title)}
                    sx={{
                      height: 150, // Reduced media height
                      objectFit: "cover",
                    }}
                  />
                </Link>
                <CardContent sx={{ padding: "12px" }}>

                  <Typography
                    variant="body2"
                    sx={{
                      fontWeight: "bold",
                      fontSize: "14px",
                      color: "gray",
                      marginBottom: "5px",
                    }}
                  >

                    {item.category}
                  </Typography>

                  <Typography
                    variant="body2"
                    color="textSecondary"
                    sx={{ fontSize: "12px", marginTop: "4px", textDecoration: "none" }}
                  >
                    <Link to={`/schriftsteller/${item.writerName}`} style={{ textDecoration: 'none' }}>
                    Von {item.writerName}
                    </Link>  • {formatDate(item.date)}
                  </Typography>
                  <Link to={`/${item.slug}`} style={{ textDecoration: "none" }}>

                    <Typography
                      variant="subtitle1"
                      component="h3"
                      sx={{
                        mt: 1,
                        fontWeight: "bold",
                        color: "#333",
                        fontSize: "14px", // Reduced font size for the title
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {truncateText(removeHtmlTags(item?.title), "title")}
                    </Typography>
                  </Link>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    sx={{
                      mt: 1,
                      fontSize: "12px", // Reduced font size for the description
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      display: "-webkit-box",
                      WebkitLineClamp: 2,
                      WebkitBoxOrient: "vertical",
                    }}
                  >
                    {truncateText(removeHtmlTags(item?.description), "description")}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default NationNews;
