import React from 'react';
import { Container, Typography, Box } from '@mui/material';
import { Helmet } from "react-helmet";

const PrivacyPolicy = () => {
  return (
    <>
    <Helmet>
        <title>Datenschutzrichtlinie</title>
      </Helmet>
    <Container sx={{ padding: '40px', backgroundColor: '#fff', borderRadius: '8px', boxShadow: 3, marginTop: '20px', width: '70%', marginBottom: '70px' }}>
      <Typography
        variant="h4"
        sx={{
          fontWeight: 700,
          marginBottom: '20px',
          color: '#333',
          borderBottom: '2px solid #0288D1',
          paddingBottom: '10px',
        }}
      >
        Datenschutzrichtlinie
      </Typography>
      <Typography
        variant="body1"
        sx={{
          fontSize: '1.1rem',
          lineHeight: '1.8',
          color: '#555',
          marginBottom: '30px',
        }}
      >
        Bei echtzeitnachrichten.de legen wir großen Wert auf den Schutz Ihrer Privatsphäre und verpflichten uns, die persönlichen Daten, die Sie mit uns teilen, zu schützen. Diese Datenschutzrichtlinie beschreibt, wie wir Ihre Daten erheben, nutzen und schützen.
      </Typography>

      <Box sx={{ marginBottom: '30px' }}>
        <Typography
          variant="h5"
          sx={{
            fontWeight: 600,
            marginBottom: '15px',
            color: '#333',
            borderBottom: '2px solid #0288D1',
          }}
        >
          Informationen, die wir sammeln
        </Typography>
        <Typography
          variant="body1"
          sx={{
            fontSize: '1.1rem',
            lineHeight: '1.8',
            color: '#555',
          }}
        >
          Wir erfassen persönliche Informationen, die Sie uns direkt zur Verfügung stellen, wie z. B.:
          <ul>
            <li>Kontaktdaten (z. B. Name, E-Mail-Adresse)</li>
            <li>Kommentare und Nachrichten, die Sie über Formulare einreichen</li>
            <li>Abonnementpräferenzen (z. B. Newsletter)</li>
          </ul>
        </Typography>
      </Box>

      <Box sx={{ marginBottom: '30px' }}>
        <Typography
          variant="h5"
          sx={{
            fontWeight: 600,
            marginBottom: '15px',
            color: '#333',
            borderBottom: '2px solid #0288D1',
          }}
        >
          Wie wir Ihre Informationen nutzen
        </Typography>
        <Typography
          variant="body1"
          sx={{
            fontSize: '1.1rem',
            lineHeight: '1.8',
            color: '#555',
          }}
        >
          Die von uns gesammelten persönlichen Informationen werden für folgende Zwecke verwendet:
          <ul>
            <li>Bereitstellung von Nachrichtenupdates und Diensten</li>
            <li>Beantwortung Ihrer Anfragen oder Wünsche</li>
            <li>Verbesserung unserer Website und Inhalte</li>
            <li>Versand von Werbematerialien oder Newslettern, sofern Sie zugestimmt haben</li>
          </ul>
        </Typography>
      </Box>

      <Box sx={{ marginBottom: '30px' }}>
        <Typography
          variant="h5"
          sx={{
            fontWeight: 600,
            marginBottom: '15px',
            color: '#333',
            borderBottom: '2px solid #0288D1',
          }}
        >
          Wie wir Ihre Informationen schützen
        </Typography>
        <Typography
          variant="body1"
          sx={{
            fontSize: '1.1rem',
            lineHeight: '1.8',
            color: '#555',
          }}
        >
          Wir setzen Sicherheitsmaßnahmen ein, um Ihre persönlichen Daten vor unbefugtem Zugriff, Änderung oder Offenlegung zu schützen. Dazu gehören Verschlüsselung, sichere Server und Zugriffsprotokolle.
        </Typography>
      </Box>

      <Box sx={{ marginBottom: '30px' }}>
        <Typography
          variant="h5"
          sx={{
            fontWeight: 600,
            marginBottom: '15px',
            color: '#333',
            borderBottom: '2px solid #0288D1',
          }}
        >
          Links zu Drittanbietern
        </Typography>
        <Typography
          variant="body1"
          sx={{
            fontSize: '1.1rem',
            lineHeight: '1.8',
            color: '#555',
          }}
        >
          Unsere Website kann Links zu Websites von Drittanbietern enthalten. Wir sind nicht verantwortlich für die Datenschutzpraktiken oder Inhalte dieser externen Seiten.
        </Typography>
      </Box>

      <Box sx={{ marginBottom: '30px' }}>
        <Typography
          variant="h5"
          sx={{
            fontWeight: 600,
            marginBottom: '15px',
            color: '#333',
            borderBottom: '2px solid #0288D1',
          }}
        >
          Cookies
        </Typography>
        <Typography
          variant="body1"
          sx={{
            fontSize: '1.1rem',
            lineHeight: '1.8',
            color: '#555',
          }}
        >
          Wir verwenden Cookies, um Ihre Erfahrung auf unserer Website zu verbessern. Cookies helfen uns, den Datenverkehr zu analysieren und personalisierte Inhalte bereitzustellen. Durch die weitere Nutzung unserer Website stimmen Sie der Verwendung von Cookies zu.
        </Typography>
      </Box>

      <Box sx={{ marginBottom: '30px' }}>
        <Typography
          variant="h5"
          sx={{
            fontWeight: 600,
            marginBottom: '15px',
            color: '#333',
            borderBottom: '2px solid #0288D1',
          }}
        >
          Änderungen an dieser Datenschutzrichtlinie
        </Typography>
        <Typography
          variant="body1"
          sx={{
            fontSize: '1.1rem',
            lineHeight: '1.8',
            color: '#555',
          }}
        >
          Wir behalten uns das Recht vor, diese Datenschutzrichtlinie jederzeit zu aktualisieren oder zu ändern. Alle Änderungen werden auf dieser Seite veröffentlicht und treten sofort in Kraft.
        </Typography>
      </Box>

      <Box sx={{ marginBottom: '30px' }}>
        <Typography
          variant="h5"
          sx={{
            fontWeight: 600,
            marginBottom: '15px',
            color: '#333',
            borderBottom: '2px solid #0288D1',
          }}
        >
          Kontakt
        </Typography>
        <Typography
          variant="body1"
          sx={{
            fontSize: '1.1rem',
            lineHeight: '1.8',
            color: '#555',
          }}
        >
          Wenn Sie Fragen zu dieser Datenschutzrichtlinie oder Ihren persönlichen Daten haben, kontaktieren Sie uns bitte unter <strong>contact@echtzeitnachrichten.de</strong>.
        </Typography>
      </Box>
    </Container>
    </>
  );
};

export default PrivacyPolicy;