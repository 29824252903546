import React, {useState, useEffect} from 'react'
import HeroSection from './HeroSection'
import Trending from './Trending'
import Business from './Business'
import Finance from './Finanace'
import NationNews from './NationNews'
import Voyage from './Voyage'
import Medias from './Medias'
import axios from 'axios'
import { Helmet } from 'react-helmet'
export const Home = () => {
  const [newsDetail, setNewsDetail] = useState(null);
  const [error, setError] = useState(null);
  useEffect(() => {
    axios
      .get(`https://echtzeitnachrichten.de/admin/api/meta`)
      .then((response) => {
        if (response.data) {
          setNewsDetail(response.data);
          // console.log("Meta",newsDetail)
        } else {
          setNewsDetail(null);
        }
      })
      .catch((err) => {
        console.error("Error fetching news details:", err);
        setError("Failed to fetch news details.");
      });
  }, []); // Empty dependency array ensures the effect runs only once
  return (
    <>
    <Helmet>
  <title>{newsDetail?.meta_title || "Echtzeit Nachrichten"}</title>
  <meta name='title' content={newsDetail?.meta_title || "Echtzeit Nachrichten"} />
  <meta name='description' content={newsDetail?.meta_description || "Echtzeitnachrichten ist Ihre zuverlässige Quelle für aktuelle Nachrichten aus Deutschland und der Welt. Verpassen Sie keine wichtigen Ereignisse und bleiben Sie stets informiert über Politik, Wirtschaft, Kultur und mehr."} />
</Helmet>

    <div>
      <HeroSection/>
      <Trending/>
      <Business/>
      <Finance/>
      <NationNews/>
      {/* <Voyage/>
      <Medias/> */}

    </div></>
  )
}
