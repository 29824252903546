import React, { useState, useEffect } from "react";
import {
  Grid,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Pagination,
  Container,
} from "@mui/material";
import { Link, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

function WriterPage() {
  const { writerName } = useParams();
  const [articles, setArticles] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const articlesPerPage = 6;

  useEffect(() => {
    const fetchArticlesByWriter = async () => {
      try {
        const response = await fetch(
          `https://echtzeitnachrichten.de/admin/api/all/news_writer?writerName=${encodeURIComponent(
            writerName
          )}`
        );
        const data = await response.json();
        setArticles(data.news || []);
      } catch (error) {
        console.error("Error fetching writer articles:", error);
      }
    };
    fetchArticlesByWriter();
  }, [writerName]);

  const indexOfLastArticle = currentPage * articlesPerPage;
  const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;
  const currentArticles = articles.slice(indexOfFirstArticle, indexOfLastArticle);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <>
    <Helmet><title>Artikel Von {writerName}</title></Helmet>
    <div style={{ padding: "40px", borderTop: "0.5px solid gray" }}>
      <Container>
      <Typography
        variant="h4"
        sx={{
          my: 4,
          fontWeight: "bold",
          textAlign: "center",
          color: "text.primary",
        }}
      >
        Artikel Von {writerName}
      </Typography>

      <Grid container spacing={4}>
        {currentArticles.map((article) => (
          <Grid item xs={12} sm={6} md={4} key={article._id}>
            <Card
              sx={{
                boxShadow: 3,
                borderRadius: 2,
                overflow: "hidden",
                transition: "transform 0.3s, box-shadow 0.3s",
                "&:hover": {
                  transform: "scale(1.02)",
                  boxShadow: 6,
                },
                height: "100%",
              }}
            >
              <Link to={`/${article.slug}`} style={{ textDecoration: "none" }}>
                <CardMedia
                  component="img"
                  height="180"
                  image={article.image || "/default-image.jpg"}
                  alt={article.title}
                  sx={{ objectFit: "cover" }}
                />
              </Link>
              <CardContent>
                <Link
                  to={`/kategorie/${article.category}`}
                  style={{ textDecoration: "none" }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      textTransform: "uppercase",
                      fontWeight: "bold",
                      color: "orange",
                      mb: 1,
                    }}
                  >
                    {article.category || "Creative"}
                  </Typography>
                </Link>
                <Link
                  to={`/${article.slug}`}
                  style={{ textDecoration: "none", color: "text.primary" }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: "bold",
                      lineHeight: 1.5,
                      display: "-webkit-box",
                      WebkitBoxOrient: "vertical",
                      overflow: "hidden",
                      WebkitLineClamp: 2,
                      color:"black",
                    }}
                  >
                    {article.title}
                  </Typography>
                </Link>
                <Typography
                  variant="body2"
                  sx={{ mt: 1, color: "text.secondary" }}
                >
                  Von {article.writerName} | {article.date}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Pagination
        count={Math.ceil(articles.length / articlesPerPage)}
        page={currentPage}
        onChange={handlePageChange}
        color="primary"
        size="large"
        sx={{ mt: 4, display: "flex", justifyContent: "center" }}
      />
      </Container>
    </div>
    </>
  );
}

export default WriterPage;
