import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Grid, Card, CardMedia, CardContent, Typography, Box, Container, Pagination } from '@mui/material';
import { formatDate } from "../../helper/utils";
import { Helmet } from 'react-helmet';

const AllNews = () => {
  const [businessData, setBusinessData] = useState([]); // State to store fetched data
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state
  const [currentPage, setCurrentPage] = useState(1); // State for the current page
  const postsPerPage = 8; // Number of posts per page
  const [totalPages, setTotalPages] = useState(1)
  const navigate = useNavigate();

  // Limit the title to 6 words
  const limitTitleToSixWords = (title) => {
    const words = title.split(' ');
    return words.length > 6 ? words.slice(0, 6).join(' ') + '...' : title;
  };

  // Fetch data from the API
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://echtzeitnachrichten.de/admin/api/all/news_page?page=${currentPage}&limit=${postsPerPage}`);
        const flattenedData = Object.values(response.data.news).flat();
        // Sort the data by date (latest first)
        const sortedData = flattenedData.slice().sort((a, b) => new Date(b.date) - new Date(a.date));
        setBusinessData(sortedData); // Set the full data
        setLoading(false);
        setTotalPages(response.data.pagination.totalPages)
      } catch (err) {
        setError('Error fetching data');
        setLoading(false);
      }
    };
    fetchData();
  }, [currentPage]); // Only run once on component mount

  // Handle page change
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  // Handle loading and error states
  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <>
    <Helmet><title>Alle Neuigkeiten</title></Helmet>
    <Box sx={{ padding: '40px 0px',  fontFamily: 'Sen, sans-serif', borderTop: '0.5px solid gray' }}>
      <Container sx={{ width: '90%', mx: 'auto' }}>
        <Typography variant="h4" sx={{ marginBottom: '20px' }}>
        Alle Neuigkeiten
        </Typography>

        {/* Grid layout for displaying news cards */}
        <Grid container spacing={4} sx={{ padding: '40px 0px' }}>
          {businessData.map((news, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <Link to={`/${news.slug}`} style={{ textDecoration: 'none' }}>
                <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                  <CardMedia
                    component="img"
                    alt={news.title}
                    height="200"
                    image={news.image ? news.image : 'default-image.jpg'} // Placeholder image if not available
                    sx={{ objectFit: 'cover' }}
                  />
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography
                      variant="body2"
                      sx={{
                        fontWeight: 'bold',
                        fontSize: '14px',
                        color: 'gray',
                        marginBottom: '5px',
                      }}
                    >
                      {news.category}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      sx={{ fontSize: '12px', marginTop: '4px', textDecoration: 'none' }}
                    >
                      <Link to={`/schriftsteller/${news.writerName}`} style={{ textDecoration: 'none' }}>
                       Von {news.writerName}
                      </Link>{' '}
                      • {formatDate(news.date)}
                    </Typography>
                    <Typography
                      variant="h6"
                      component="p"
                      sx={{ fontWeight: 'bold', fontSize: '16px', color: 'black' }}
                    >
                      {limitTitleToSixWords(news.title)}
                    </Typography>
                  </CardContent>
                </Card>
              </Link>
            </Grid>
          ))}
        </Grid>

        {/* Pagination Controls */}
        <Box sx={{ mt: 4, mb:4, display: 'flex', justifyContent: 'center' }}>
          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={handlePageChange}
            color="primary"
          />
        </Box>
      </Container>
    </Box>
    </>
  );
};

export default AllNews;
