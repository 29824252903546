import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Card,
  CardMedia,
  CardContent,
  Container,
} from "@mui/material";
import axios from "axios";
import { Link } from "react-router-dom";
// Base URL for API
const BASE_URL = "https://echtzeitnachrichten.de";

// Utility Function to truncate text
const truncateText = (text, type = "title") => {
  const maxLength = type === "title" ? 70 : 100;
  if (!text) return "";
  return text.length > maxLength ? text.slice(0, maxLength) + "..." : text;
};

// Utility function to remove HTML tags from a string
const removeHtmlTags = (str) => {
  if (!str) return "";
  return str.replace(/<[^>]*>/g, ""); // Remove all HTML tags
};
const formatDate = (date) => {
  const formattedDate = new Date(date);
  if (!isNaN(formattedDate.getTime())) {
    return formattedDate.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  }
  return "Invalid Date";
};

// Fetch Data Function to get popular news
const fetchPopularNews = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/admin/api/popular/news`);
    return response.data.popularNews || []; // Adjust based on the correct structure
  } catch (error) {
    console.error("Error fetching popular news:", error);
    return []; // Return empty array in case of error
  }
};

// Trending Component
const Trending = () => {
  const [news, setNews] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchPopularNews();
        setNews(response); // Update the state with fetched news
      } catch (error) {
        console.error("Error fetching trending news:", error);
      }
    };
    fetchData();
  }, []);

  return (
    <Box sx={{ marginBottom: "40px" }}>
      <Container>
        <Typography
          variant="h5"
          component="h2"
          sx={{
            padding: "0px 16px",
            fontWeight: "bold",
            mb: 2,
            fontSize: "22px",
            margin: "30px 0px",
            position: "relative",
            paddingLeft: "16px",
            "&::before": {
              content: '""',
              position: "absolute",
              left: 0,
              top: "50%",
              transform: "translateY(-50%)",
              width: "4px",
              height: "100%",
              backgroundColor: "red", // Changed to red
              borderRadius: "3px",
            },
            color: "red", // Changed text color to red
          }}
        >
          Trendbeiträge
        </Typography>

        <Grid container spacing={3}>
          {news.slice(0, 4).map((item, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <Card
                sx={{
                  boxShadow: "none",
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  transition: "transform 0.3s ease, box-shadow 0.3s ease",
                  "&:hover": {
                    transform: "scale(1.05)",
                    boxShadow: "0 6px 12px rgba(0, 0, 0, 0.1)",
                  },
                }}
              >
                <Link to={`/${item.slug}`} style={{ textDecoration: "none" }}>
                  <CardMedia
                    component="img"
                    sx={{
                      height: 150,
                      borderRadius: "8px",
                      objectFit: "cover",
                      marginBottom: "10px",
                    }}
                    image={item.image || "https://via.placeholder.com/150"}
                    alt={item.title}
                  />
                  <CardContent >
                    <Typography
                      variant="body2"
                      sx={{
                        fontWeight: "bold",
                        fontSize: "14px",
                        color: "gray",
                        marginBottom: "5px",
                      }}
                    >
                      {item.category}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      sx={{ fontSize: "12px", marginTop: "4px", textDecoration: "none" }}
                    >
                      <Link to={`/schriftsteller/${item.writerName}`} style={{ textDecoration: 'none' }}>
                      Von {item.writerName}
                      </Link>  • {formatDate(item.date)}
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        fontWeight: "bold",
                        fontSize: "16px",
                        color: "#333",
                      }}
                    >
                      {truncateText(item.title, "title")}
                    </Typography>
                  </CardContent>
                </Link>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default Trending;
