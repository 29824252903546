import React, { useEffect, useState, useReducer } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { CircularProgress, Card, CardMedia, CardContent, Typography, Divider, Avatar, Grid, Container } from '@mui/material';
import { useMediaQuery } from '@mui/material';
import { removeHtmlTags, formatDate, truncateText } from '../../helper/utils';
import { Link } from 'react-router-dom';
import TableOfContents from './TableOfContents';
import { Helmet } from 'react-helmet';
const BASE_URL = "https://echtzeitnachrichten.de";

const initialState = {
    post: null,
    latestNews: [],
    isLoading: true,
    error: null,
    tocHeadings: [],
};

function reducer(state, action) {
    switch (action.type) {
        case 'FETCH_SUCCESS':
            return {
                ...state,
                post: action.payload.post,
                latestNews: action.payload.latestNews,
                isLoading: false,
            };
        case 'FETCH_ERROR':
            return {
                ...state,
                error: action.payload,
                isLoading: false,
            };
        case 'SET_HEADINGS':
            return { ...state, tocHeadings: action.payload };
        default:
            return state;
    }
}

function PostDetails() {
    const { slug } = useParams();
    const [state, dispatch] = useReducer(reducer, initialState);
    const { post, latestNews, error, tocHeadings } = state;
    const [activeTocItem, setActiveTocItem] = useState(null);
    const [processedContent, setProcessedContent] = useState(null);
    const isMobile = useMediaQuery('(max-width:768px)');

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    const extractHeadings = (description) => {
        const parser = new DOMParser();
        const htmlContent = parser.parseFromString(description, 'text/html');
        const headings = htmlContent.querySelectorAll('h1, h2, h3, h4, h5, h6');
        headings.forEach((heading, index) => {
            heading.id = `heading-${index + 1}`;
        });
        return {
            headings: Array.from(headings).map(h => ({ id: h.id, text: h.innerText })),
            modifiedDescription: htmlContent.body.innerHTML,
        };
    };

    useEffect(() => {
        const fetchPostDetails = async () => {
            try {
                const postResponse = await axios.get(`${BASE_URL}/admin/api/news/details/${slug}`);
                const latestNewsResponse = await axios.get(`${BASE_URL}/admin/api/latest/news`);

                const { headings, modifiedDescription } = extractHeadings(postResponse.data.news.description);

                dispatch({ type: 'FETCH_SUCCESS', payload: { post: postResponse.data.news, latestNews: latestNewsResponse.data.news } });
                setProcessedContent(modifiedDescription);
                dispatch({ type: 'SET_HEADINGS', payload: headings });
            } catch (error) {
                console.error('Error fetching data:', error);
                dispatch({ type: 'FETCH_ERROR', payload: 'Failed to load data' });
            }
        };

        if (slug) {
            fetchPostDetails();
        }
    }, [slug]);

    useEffect(() => {
        const debounce = (func, wait) => {
            let timeout;
            return function (...args) {
                clearTimeout(timeout);
                timeout = setTimeout(() => func.apply(this, args), wait);
            };
        };

        const onScroll = debounce(() => {
            const headings = document.querySelectorAll('h1, h2, h3, h4, h5, h6');
            let activeId = null;
            headings.forEach((heading) => {
                const rect = heading.getBoundingClientRect();
                if (rect.top <= 0 && rect.bottom >= 0) {
                    activeId = heading.id;
                }
            });
            setActiveTocItem(activeId);
        }, 100);

        window.addEventListener('scroll', onScroll);
        return () => window.removeEventListener('scroll', onScroll);
    }, []);

    useEffect(() => {
        if (post) {
            const metaTitle = post.meta_title || post.title || "Echtzeit Nachrichten";
            const metaDescription = post.meta_description || post.description;

            document.title = metaTitle;
            const updateMetaTag = (name, content) => {
                let tag = document.querySelector(`meta[name="${name}"]`);
                if (!tag) {
                    tag = document.createElement('meta');
                    tag.name = name;
                    document.head.appendChild(tag);
                }
                tag.content = content;
            };

            updateMetaTag('title', metaTitle);
            updateMetaTag('description', metaDescription);
        }
    }, [post]);

    if (state.isLoading) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
            </div>
        );
    }

    if (error) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <Typography variant="h6" color="error">{error}</Typography>
            </div>
        );
    }

    if (!post) {
        return (
            <div style={{ padding: '20px', textAlign: 'center' }}>
                <Typography variant="h6" color="textSecondary">
                    Post not found.
                </Typography>
            </div>
        );
    }

    // const paddingStyle = isMobile ? '20px' : '0px 100px';
    return (
        <>
        <Helmet><title>{post.title}</title>
        <meta name='title' content={post.meta_title}/>
        <meta name='description' content={post.meta_description}/>
        </Helmet>
    <div style={{ padding: isMobile ? '16px' : '30px 100px' , borderTop: "0.5px solid gray", display:"flex", flexDirection:"column", alignItems:"center"}}>
                <Grid container spacing={3}marginBottom={3} width={'70%'}>
                    {/* Post Details */}
                    <Grid item xs={12} md={8}>
                        <Card sx={{ boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)', borderRadius: '10px' }}>
                            <CardContent style={{ padding: '30px' }}>
                                <Typography variant="h5" style={{ fontWeight: 'bold', marginBottom: '15px' }} id="post-title">
                                    {post.title}
                                </Typography>

                                <Typography variant="body2" color="textSecondary" style={{ display: 'flex', alignItems: 'center' }}>
                                    <Avatar
                                        alt={removeHtmlTags(post.writerName || 'Unknown')}
                                        src={post.writerImage || '/default-profile.png'}
                                        sx={{ width: 30, height: 30, marginRight: '10px', boxShadow: '0 0 5px rgba(0, 0, 0, 0.1)' }}
                                    />
                                    <span style={{ marginRight: "10px", fontWeight: "bold" }}>
                                        {post.writerName || 'Unknown'}
                                    </span>
                                    <span>{formatDate(post.date || post._id)}</span>
                                </Typography>

                                <CardMedia
                                    component="img"
                                    image={post.image || '/default-image.jpg'}
                                    alt={post.title}
                                    style={{ width: '100%', height: '400px', objectFit: 'cover', borderRadius: '10px', marginTop: '20px' }}
                                />
                                <Divider style={{ margin: '20px 0' }} />
                                <TableOfContents content={processedContent} />
                                {/* <TableOfContents headings={tocHeadings} activeItem={activeTocItem} /> */}
                                <Typography variant="body2" color="textSecondary" dangerouslySetInnerHTML={{ __html: processedContent || 'No description available.' }} />
                            </CardContent>
                        </Card>
                    </Grid>

                    {/* Latest News Sidebar */}
                    <Grid item xs={12} md={4}>
                        <Typography
                            variant="h5"
                            component="h2"
                            sx={{
                                // padding: "0px 16px",
                                fontWeight: "bold",
                                mb: 2,
                                fontSize: "22px",
                                // margin: "30px 0px",
                                position: "relative",
                                paddingLeft: "16px",
                                "&::before": {
                                    content: '""',
                                    position: "absolute",
                                    left: 0,
                                    top: "50%",
                                    transform: "translateY(-50%)",
                                    width: "4px",
                                    height: "100%",
                                    backgroundColor: "red", // Changed to red
                                    borderRadius: "3px",
                                },
                                color: "red", // Changed text color to red
                            }}
                        >
                            Neueste Nachrichten
                        </Typography>
                        {latestNews.length === 0 ? (
                            <Typography variant="body2" color="textSecondary">Keine aktuellen Nachrichten verfügbar
                            </Typography>
                        ) : (
                            latestNews.map((newsItem, index) => (
                                <Card key={index} sx={{ display: 'flex', alignItems: 'center', padding: '10px', borderRadius: '8px', backgroundColor: '#fff', width: '100%', maxWidth: '350px', marginBottom: '15px' }}>
                                    <Link to={`/${newsItem.slug}`} style={{ textDecoration: 'none', width: '120px' }}>
                                        <CardMedia
                                            component="img"
                                            image={newsItem.image || '/default-image.jpg'}
                                            alt={newsItem.title}
                                            style={{
                                                width: '120px',
                                                height: '100px',
                                                objectFit: 'cover',
                                                borderRadius: '8px',
                                                marginRight: '20px',
                                            }}
                                        />
                                    </Link>
                                    <CardContent style={{ marginLeft: "20px", display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: '0' }}>
                                        <Link to={`/${newsItem.slug}`} style={{ textDecoration: 'none', width: '100%' }}>
                                            <Typography variant="body2" style={{ fontWeight: 'bold', fontSize: '14px', color: '#333', marginBottom: '6px' }}>
                                                {truncateText(newsItem.title)}
                                            </Typography>
                                        </Link>
                                        <Link to={`/${newsItem.slug}`} style={{ textDecoration: 'none' }}>
                                            <Typography
                                                variant="caption"
                                                sx={{
                                                    color: "gray",
                                                    textTransform: "uppercase",
                                                    fontSize: "12px",
                                                }}
                                            >
                                                {newsItem.category}

                                            </Typography>
                                        </Link>
                                        <Typography variant="body2" color="textSecondary" style={{ display: 'flex', alignItems: 'center' }}>
                                            <span style={{ marginRight: "10px", fontWeight: "bold" }}>
                                                {post.writerName || 'Unknown'}
                                            </span>
                                            <span>{formatDate(post.date || post._id)}</span>
                                        </Typography>
                                    </CardContent>
                                </Card>
                                
                            ))
                        )}
                    </Grid>
                </Grid>
            

        </div>
        </>
    );
}

export default PostDetails;
