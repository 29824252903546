import React, { useState, useEffect } from 'react';
import { Grid, Typography, Box, Button } from '@mui/material';
import { NavLink } from 'react-router-dom'; // Import NavLink
import axios from 'axios';
import { Link } from 'react-router-dom';
import logo from "../../assets/logo-f.png"
const Footer = () => {
  const [topPosts, setTopPosts] = useState([]); // State to store top posts
  const [isLoading, setIsLoading] = useState(true); // Loading state for posts
  const [error, setError] = useState(null); // Error state if fetching fails

  // Fetch top posts from the API
  useEffect(() => {
    const fetchTopPosts = async () => {
      try {
        const result = await axios.get('https://echtzeitnachrichten.de/admin/api/recent/news');
        setTopPosts(result.data.news || []); // Set top posts data
        setIsLoading(false); // Set loading to false after data is fetched
      } catch (err) {
        console.error('Error fetching top posts:', err);
        setError('Failed to load posts');
        setIsLoading(false);
      }
    };

    fetchTopPosts(); // Fetch data when the component mounts
  }, []); // Empty dependency array to fetch on mount

  // Limit to only 2 posts
  const limitedTopPosts = topPosts.slice(0, 2);
  const formatDate = (date) => {
    const formattedDate = new Date(date);
    if (!isNaN(formattedDate.getTime())) { // Check if the date is valid
      return formattedDate.toLocaleDateString('en-US', {
        year: 'numeric', // "2024"
        month: 'long', // "November"
        day: 'numeric', // "13"
      });
    }
    return 'Invalid Date';
  };

  // Retry fetching the posts
  const handleRetry = () => {
    setIsLoading(true);
    setError(null);
    // Retry the data fetching
    const fetchTopPosts = async () => {
      try {
        const result = await axios.get('https://echtzeitnachrichten.de/admin/api/recent/news');
        setTopPosts(result.data.news || []); // Set top posts data
        setIsLoading(false); // Set loading to false after data is fetched
      } catch (err) {
        console.error('Error fetching top posts:', err);
        setError('Failed to load posts');
        setIsLoading(false);
      }
    };
    fetchTopPosts();
  };

  // Default image if post image is not available
  const defaultImage = 'https://via.placeholder.com/80';

  return (
    <Box sx={{backgroundColor:'#121418', color: 'white', display: 'flex', flexDirection: 'column', width:'100%', paddingTop: '32px' }}>
      <Box sx={{ width: '60%', mx: 'auto'}}> {/* Container style similar to AllNews */}
        <Grid container spacing={4} backgroundColor='#121418' fontFamily="sans-serif" color="#b9b9b9" >
          <Grid item xs={12} md={6}>
            <Box sx={{ mb: 4 }}>
              <a href="/">
              <img src={logo} height={"100px"}/></a>
              <Typography variant="body2" sx={{ mb: 2, fontSize: { xs: "14px", sm: "16px", md: "18px" }, lineHeight: 1.5 }}>
              Echtzeitnachrichten ist Ihre zuverlässige Quelle für aktuelle Nachrichten aus Deutschland und der Welt. Verpassen Sie keine wichtigen Ereignisse und bleiben Sie stets informiert über Politik, Wirtschaft, Kultur und mehr.
              </Typography>
              <Typography variant="h7" component="div" fontFamily="sans-serif" mt="10px">
                <span style={{ fontWeight: "600", color: '#FFFFFFFF' }}>Schicken Sie uns eine E-Mail:</span>
                <span> contact@echtzeitnachrichten.de</span>
              </Typography>
            </Box>
          </Grid>
          {/* Top Posts Section */}
          <Grid item xs={12} md={6}>
            <ul style={{ listStyle: 'none', paddingLeft: 0 }}>
              <Typography variant="h6" sx={{ fontWeight: 'bold', mb: "16px", color: '#FFFFFFFF' }}>Top-Beiträge</Typography>
              {isLoading ? (
                <Typography variant="body2" sx={{ color: '#ffffff' }}>Loading...</Typography>
              ) : error ? (
                <>
                  <Typography variant="body2" sx={{ color: '#ff0000' }}>{error}</Typography>
                  <Button variant="contained" color="secondary" sx={{ mt: 2 }} onClick={handleRetry}>
                    Retry
                  </Button>
                </>
              ) : (
                limitedTopPosts.map((post, index) => (
                  <li key={index} style={{
                    marginBottom: '20px',
                    display: 'flex',
                    flexDirection: { xs: 'column', sm: 'row' }, // Stack vertically on xs, row on sm and up
                    alignItems: 'center'
                  }}>
                    {/* Image with Radius */}
                    <Link to={`/${post.slug}`} style={{ textDecoration: "none" }}>
                      <Box sx={{ width: '80px', height: '80px', borderRadius: '10%', overflow: 'hidden', marginRight: '15px' }}>
                        <img src={post.image || defaultImage} alt="post" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                      </Box>
                    </Link>
                    {/* Text and Date */}
                    <Box sx={{ mt: { xs: 2, sm: 0 } }}>
                      <Link to={`/${post.slug}`} style={{ textDecoration: "none" }}>
                        <Typography variant="body2" component="div" fontFamily="sans-serif">
                          <span style={{ fontSize: '16px', fontWeight: '600', display: 'block', color: "#FFFFFFFF" }}>
                            {post.title || 'Untitled Post'}
                          </span>
                        </Typography>
                      </Link>
                      <Typography>
                        <span style={{ fontSize: '0.75rem', marginLeft: '8px', display: 'block', marginTop: '5px' }}>
                          {formatDate(post.date)}
                        </span>
                      </Typography>
                    </Box>
                  </li>
                ))
              )}
            </ul>
          </Grid>
        </Grid>
      </Box>
      {/* Footer Section */}
      <Box sx={{ borderTop: '1px solid rgba(255, 255, 255, 0.3)', p: "10px 40px", display: 'flex', flexDirection: 'column', alignItems: 'center'}} backgroundColor='#000000' fontFamily="sans-serif">
        <Grid container spacing={2} width="68%" alignItems="center" p={{ xs: '0px 20px', sm: '0px 20px', md: '0px 20px' }} pb={0}>
          <Grid item xs={6} sm={6} md={6}>
            <Box>
              <Typography variant="body2" align="left" sx={{ fontSize: { xs: '14px', sm: '16px', md: '18px' } }}>
                <strong>Copyright</strong> 2024. Alle Rechte vorbehalten.
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <Box sx={{ display: 'flex', justifyContent: { xs: 'center', sm: 'flex-start' }, flexDirection: { xs: 'column', sm: 'row' }, gap: { xs: '10px', sm: '20px' }, padding: { xs: '10px', sm: '0' } }}>
              <ul style={{ listStyle: 'none', paddingLeft: 0, marginBottom: "0", fontFamily: "sans-serif", color: '#b9b9b9', display: 'flex', flexDirection: 'row', gap: '10px' }}>
                <li style={{ fontSize: '14px' }}>
                  <NavLink to="/über-uns" style={({ isActive }) => ({
                    color: isActive ? '#ffffff' : '#b9b9b9', // Active link color
                    textDecoration: 'none', // Remove underline
                    fontWeight: 'bold',
                  })}>
                    Über uns
                  </NavLink>
                </li>
                <li style={{ fontSize: '14px' }}>
                  <NavLink to="/kontakt" style={({ isActive }) => ({
                    color: isActive ? '#ffffff' : '#b9b9b9', // Active link color
                    textDecoration: 'none', // Remove underline
                    fontWeight: 'bold',
                  })}>
                    Kontaktieren Sie uns
                  </NavLink>
                </li>
                <li style={{ fontSize: '14px' }}>
                  <NavLink to="/privatsphäre" style={({ isActive }) => ({
                    color: isActive ? '#ffffff' : '#b9b9b9', // Active link color
                    textDecoration: 'none', // Remove underline
                    fontWeight: 'bold',
                  })}>
                    Datenschutzrichtlinie
                  </NavLink>
                </li>
              </ul>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Footer;
