import React, { useState } from 'react';
import { Box, Typography, Container, Button, TextField, Grid } from '@mui/material';
import { Helmet } from "react-helmet";
const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here (e.g., send data to a server or display an alert)
    alert('Message sent successfully!');
  };

  return (
    <>
    <Helmet>
        <title>Kotact</title>
      </Helmet>
    <Container sx={{ padding: '40px', backgroundColor: '#fff', borderRadius: '8px', boxShadow: 3, marginTop: '20px', width: '70%' ,marginBottom:"70px"}}>
      <Typography
        variant="h4"
        sx={{
          fontWeight: 700,
          marginBottom: '20px',
          color: '#333',
          borderBottom: '2px solid #0288D1', // Blue color border for headings
          paddingBottom: '10px',
          width: '400px',
        }}
      >
        Kontaktieren Sie uns
      </Typography>
      <Typography
        variant="body1"
        sx={{
          fontSize: '1.1rem',
          lineHeight: '1.8',
          color: '#555',
          marginBottom: '30px',
        }}
      >
        Wenn Sie Fragen, Feedback oder Anregungen haben, können Sie sich gerne an uns wenden. Wir würden uns freuen, von Ihnen zu hören!
      </Typography>

      <form onSubmit={handleSubmit}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <TextField
              label="Your Name"
              variant="outlined"
              fullWidth
              name="name"
              value={formData.name}
              onChange={handleChange}
              sx={{ marginBottom: '20px' }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="Your Email"
              variant="outlined"
              fullWidth
              name="email"
              value={formData.email}
              onChange={handleChange}
              sx={{ marginBottom: '20px' }}
            />
          </Grid>
        </Grid>
        <TextField
          label="Your Message"
          variant="outlined"
          multiline
          rows={4}
          fullWidth
          name="message"
          value={formData.message}
          onChange={handleChange}
          sx={{ marginBottom: '30px' }}
        />
        <Box sx={{ textAlign: 'center' }}>
          <Button
            variant="contained"
            sx={{
              backgroundColor: '#0288D1',
              color: '#fff',
              padding: '12px 20px',
              textTransform: 'none',
              '&:hover': {
                backgroundColor: '#0277BD',
              },
            }}
            type="submit"
          >
            Nachricht senden
          </Button>
        </Box>
      </form>
    </Container>
    </>
  );
};

export default ContactUs;
